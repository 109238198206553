<template>
 <div>
   <afficheDetailsNormal v-if="!$store.state.user.elderModel"/>
   <afficheDetailsElder v-else />
 </div>
</template>

<script>
import afficheDetailsNormal from './afficheDetails-normal.vue'
import afficheDetailsElder from './afficheDetails-elder.vue'
 export default {
   name: '',
   components: {
     afficheDetailsNormal,
     afficheDetailsElder

   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>

