<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>公告详情</span>
      </div>
      <div class="details-content">
        <h3 class="details-title">{{ afficheDetails.title }}</h3>
        <div class="pubdate">
          <div>
            <van-icon class="icon" size="14" name="clock-o" />
            <span>发布时间：{{ afficheDetails.releaseTime }}</span>
          </div>
        </div>
        <div class="relation">
          <div>
            <van-icon class="icon" size="14" name="eye-o" />
            <span>浏览量：{{ afficheDetails.readAmount }}</span>
          </div>
        </div>
        <div class="article-contents">
          <p>{{ afficheDetails.content }}</p>
        </div>
      </div>
    </layout>
  </div>
</template>
<script>
import Layout from '@/components/layout.vue'
import { getannouncementDetail, getannouncementview } from '@/api/home'
export default {
  components: {
    Layout
  },
  data() {
    return {
      afficheDetails: {}
    }
  },
  created() {
    this.getannouncementDetail()
    this.getannouncementview()
  },
  methods: {
    // 返回上一页
    returnHome() {
      this.$router.go(-1)
    },
    getannouncementDetail() {
      getannouncementDetail({
        announcementId: this.$route.query.id
      }).then((res) => {
        this.afficheDetails = res.result
      })
    },
    getannouncementview() {
      getannouncementview({
        announcementId: this.$route.query.id
      }).then((res) => {
        console.log(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
  .details-content {
    padding: 16px 16px 30px 16px;
    .title {
      width: 343px;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #12151b;
    }
    .pubdate,
    .relation {
      display: flex;
      justify-content: space-between;
      line-height: 24px;
      margin-top: 16px;
      .icon {
        line-height: 24px;
        margin-right: 9px;
      }
      span {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #868a93;
        line-height: 24px;
        letter-spacing: 0px;
      }
      .type {
        width: 67px;
        height: 24px;
        text-align: center;
        border-radius: 12px;
        background: #e4f0ff;
        font-size: 11px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;

        color: #2083ff;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
    .relation {
      margin-top: 0 !important;
    }

    .article-contents {
      margin-top: 16px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #464a55;
      line-height: 21px;
      letter-spacing: 0px;
    }
  }
}
</style>
