import { render, staticRenderFns } from "./afficheDetails.vue?vue&type=template&id=5715f33e&scoped=true&"
import script from "./afficheDetails.vue?vue&type=script&lang=js&"
export * from "./afficheDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5715f33e",
  null
  
)

export default component.exports